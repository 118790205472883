import request from "@/utils/request";
export function fileList(query) {
  return request({
    url: "/admin/sys-file/page",
    method: "get",
    params: query,
  });
}

export function addObj(obj) {
  return request({
    url: "/admin/sys-file",
    method: "post",
    data: obj,
  });
}

export function getObj(id) {
  return request({
    url: "/admin/sys-file/" + id,
    method: "get",
  });
}

export function putObj(obj) {
  return request({
    url: "/admin/sys-file",
    method: "put",
    data: obj,
  });
}
export function uploadRename(obj) {
  return request({
    url: "/admin/sys-file/rename",
    method: "put",
    data: obj,
  });
}
export function fileGroupAdd(params) {
  return request({
    url: "/admin/sys-file/group/add",
    method: "post",
    data: params,
  });
}

export function fileGroupUpdate(params) {
  return request({
    url: "/admin/sys-file/group/update",
    method: "put",
    data: params,
  });
}

// 文件分类删除
export function fileGroupDelete(id) {
  return request({
    url: "/admin/sys-file/group/delete/" + id,
    method: "delete",
  });
}

// 文件分类列表
export function fileCateLists(params) {
  return request({
    url: "/admin/sys-file/group/list",
    method: "get",
    params: params,
  });
}

// 文件删除
export function fileDelete(id) {
  return request({
    url: "/admin/sys-file",
    method: "delete",
    data: id,
  });
}

// 文件移动
export function fileMove(params) {
  return request({
    url: "/admin/sys-file/group/move",
    method: "put",
    data: params,
  });
}

// // 文件重命名
// export function fileRename(params: { id,original}) {
//   return request({
//     url: "/admin/sys-file/rename",
//     method: "put",
//     data: params,
//   });
// }

/**
 * 分片上传
 * @param data
 * @returns
 */
export function bigFileUpload(data) {
  return request({
    url: "/study/bigFile/oss/chunk/upload",
    method: "post",
    data,
  });
}
