import request from '@/utils/request'

export const shopUpdate = async (data) => request({ url: "/easyapp/shop/update", method: "put", data })
export const shopSave= async (data) => request({ url: "/easyapp/shop/save", method: "post", data })
/**
 * 删除
 */
export const shopDel = async data => request({ url: '/easyapp/shop', method: 'DELETE', data })

//获取标签导航数据源
export const comDSList = async (params) => request({url: "/easyapp/config/comDSList", method: "get", params})

//根据规则标识获取数据源接口（新
export const getDatasourceList = async (params) => request({url: "/easyapp/datasource/getDatasourceList", method: "get", params})

//查询规则下的装饰器接口
//easyapp/decorator/getDecoratorListByRuleIds?ruleIds=courseList

export const getDecoratorListByRuleIds = async (params) => request({url: "/easyapp/decorator/getDecoratorListByRuleIds", method: "get", params})

export const datasourcePage = async (params) => request({url: "/easyapp/datasource/page", method: "get", params})




export const getRuleList= async (data) => request({ url: "/easyapp/rule/getListBy", method: "post", data })


export const getCategoryList = async (params) => request({url: params.url, method: "get", params})

export const getCategoryListPost = async (data) => request({url: data.url, method: "post", data})

export const getProjectDetail = async (params) => request({url: "/easyapp/shop/" + params, method: "get"})

//组件分组

export const getTenantComponentGroupList = async (params) => request({url: '/easyapp/componentGroup/getTenantComponentGroupList', method: "get", params})