<!-- @Description: 素材库 -->
<template>
  <div>
    <el-dialog title="素材管理" :visible.sync="dialogVisible" width="680px" @close="materialDialog">
      <div slot="title" class="header-title materialTitle"><span>素材管理</span></div>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-if="!uploadType || uploadType.includes('image')" label="图片" name="first"></el-tab-pane>
        <el-tab-pane v-if="!uploadType || uploadType.includes('icon')" label="图标" name="second"></el-tab-pane>
      </el-tabs>

      <div v-if="activeName == 'first'" class="flex pic-content">
        <div class="cont-left">
          <el-tree
            style="min-height: 400px;overflow-y: auto;
            margin-top: 30px;"
            ref="treeRef"
            node-key="id"
            :data="cateLists"
            :empty-text="emptyText"
            :highlight-current="true"
            :expand-on-click-node="false"
            :default-expand-all="true"
            current-node-key="-1"
            @node-click="handleCatSelect">
            <div slot-scope="{ node, data }" style="display: flex;align-items: center;">
              <span class="custom-tree-node"> {{data.name}} </span>
              <el-button v-if="data.id !== '' && data.id != '-1'" type="text" size="mini" style="color: #606266;" icon="el-icon-more" @click="append($event, data)"> </el-button>
            </div>
          </el-tree>
          <el-button v-if="activeName == 'first'" type="text" icon="el-icon-upload" @click="uploadPic" class="upload-pic">上传图片</el-button>
        </div>
        <div class="cont-right" style="height: 470px;overflow-y: auto;">
          <p>
            <el-form :model="searchForm" ref="form" :inline="true" size="mini">
              <el-form-item>
                <el-input style="width: 150px;" v-model="searchForm.original" placeholder="请输图片名称搜索" clearable @change="searchPic"></el-input>
                <el-button size="mini" type="primary" style="margin-left: 5px;height: 28px;" @click="searchPic">搜索</el-button>
                <el-button size="mini" style="margin-left: 5px;height: 28px;" @click="resetPic">重置</el-button>
                <el-button size="mini" type="primary" style="margin-left: 5px;height: 28px;" @click="movePic">移动</el-button>
              </el-form-item>
            </el-form>
          </p>
          <div class="flex flex-wrap row-left p13 text-center">
            <div
              v-for="(item, index) in groupList"
              :key="index"
              :style="{ border: index == activeImage ? '1px solid #409eff' : '', borderRadius: '5px' }"
              class="rightItem"
              @mouseover="ImagemMouseOver(index,item)"
              @click="ImageItem(index, item)"
              style="position: relative;">
              <!-- <i v-if="imageClearShow ==index" class="icon icon-scdelete imageClear" @click="deleteImage(item)"></i> -->
              <div class="rightItemImg">
                <img style="width: 100%;height: 100%;" :src="project.filePath+'/person/' + item.fileName" />
              </div>
              <span>{{ item.original }}</span>
              <div style="display: flex;align-items: center;justify-content: center;">
                <!-- <el-tooltip placement="bottom" effect="light" :manual="true" v-if="renameHandleShow">
                  <div slot="content">
                    <div style="display: flex;align-items: center;">
                      <el-input v-model="groupRename" size="mini" maxlength="20" minlength="0">
                        <span slot="suffix" class="slotSuffix">0/20</span>
                      </el-input>
                      <el-button style="margin-left: 10px;" size="mini">取 消</el-button>
                      <el-button type="primary" @click="updateGroupRename" size="mini">确 定</el-button>
                    </div>
                  </div>
                </el-tooltip> -->
              </div>
            </div>
            <div class="noDate" v-if="groupList.length == 0">暂无数据~</div>
          </div>
        </div>
      </div>

      <div v-if="activeName == 'second'" class="flex pic-content">
        <el-tabs type="border-card">
          <div class="grid" style="max-height: 450px;overflow-y: scroll;">
            <div v-for="(item, index) of iconList" :key="index" @click="selectItem(item, 'ali', index)">
              <!--  @click="generateIconCode(item)" -->
              <div class="icon-item" :style="{ color: index == active ? '#409eff' : '' }">
                <i :class="item.icon" style="font-size: 30px;"></i>
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="materialDialog" size="mini">取 消</el-button>
        <el-button type="primary" @click="Confirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="notheader" :style="{ top: clientY - 120 + 'px' }" :visible.sync="groupHandleShow" width="100px" :show-close="false" :modal="false">
      <div class="triangle"></div>
      <div class="groupHandle" v-if="groupHandleShow">
        <p @click="addGroupHandle">新增分组</p>
        <p style="margin-top: 20px;" @click="deleteGroup">删除分组</p>
      </div>
    </el-dialog>
    <el-dialog title="移动" :visible.sync="moveImageVisible" width="400px" @close="moveDialogClose">
      <div style="display: flex;">
        <span class="moveDialogLabel">移动文件至</span>
        <el-select v-model="moveImageId" placeholder="请选择分组">
          <el-option v-for="item in moveImageList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="moveDialogClose" size="mini">取 消</el-button>
        <el-button type="primary" @click="moveImageConfirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <uploadPic ref="uploadRef" @uploadRefresh="uploadRefresh"></uploadPic>
    <addGroup ref="addGroupref" :groupList="groupList" @groupRefresh="groupRefresh"></addGroup>
  </div>
</template>

<script>
import { fileCateLists, fileList, fileGroupDelete,fileDelete,fileMove } from "@/api/material";
import clipboard from './clipboard'
// import svgIcons from './svg-icons'
import elementIcons from './element-icons'
import uploadPic from "../uploadPic";
import addGroup from "../uploadPic/addGroup";
import { mapGetters } from "vuex";

export default {
  name: 'material',
  components: {
    uploadPic,
    addGroup
  },
  props: ['uploadType'],
  data() {
    return {
      active: null,
      // svgIcons,
      iconList: [
        { icon: 'iconfont icon-tuijian', name: 'icon-tuijian', label: '推荐' },
        { icon: 'iconfont icon-shouye1', name: 'icon-shouye1', label: '首页1' },
        { icon: 'iconfont icon-shouye2', name: 'icon-shouye2', label: '首页2' },
        { icon: 'iconfont icon-shouye3', name: 'icon-shouye3', label: '首页3' },

        { icon: 'iconfont icon-a-bianzu10beifen3', name: 'icon-a-bianzu10beifen3', label: '学习' },
        { icon: 'iconfont icon-xuexi3', name: 'icon-xuexi3', label: '学习2' },
        { icon: 'iconfont icon-xuexi1', name: 'icon-xuexi1', label: '学习1' },

        { icon: 'iconfont icon-zixun', name: 'icon-zixun', label: '资讯' },

        { icon: 'iconfont icon-zixun1', name: 'icon-zixun1', label: '资讯1' },
        { icon: 'iconfont icon-zixun2', name: 'icon-zixun2', label: '资讯2' },
        { icon: 'iconfont icon-userCenter', name: 'icon-userCenter', label: '我的' },
        { icon: 'iconfont icon-wode1', name: 'icon-wode1', label: '我的1' },

        { icon: 'iconfont icon-wode3', name: 'icon-wode3', label: '我的3' },

      ],
      elementIcons,
      scon: '',
      iconactive: 'ali',
      dialogVisible: false,
      activeName: 'first',
      groupingData: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
          children: [{
            label: '三级 1-1-1'
          }]
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
        }, {
          label: '二级 2-2',
        }]
      }],

      picture: [
        { name: 'xx1', path: 'https://wx3.sinaimg.cn/mw690/0070NSSfgy1hqa9j5i5ebj30u0140dnz.jpg' },
        { name: 'xx2', path: 'https://ww2.sinaimg.cn/mw690/0070NSSfgy1hq39jbdyuwj31z41z4ng7.jpg' },
        { name: 'xx3', path: 'https://wx4.sinaimg.cn/large/007fE4bAly4gqa3zx8as0j30u00jzmyb.jpg' },
        { name: 'xx4', path: 'https://inews.gtimg.com/om_bt/O10C3cMUVfWPIV6QqLGYhlOHrhteBRqi0CSh2uvYNu6hAAA/641' },
        { name: 'xx4', path: 'https://inews.gtimg.com/om_bt/O10C3cMUVfWPIV6QqLGYhlOHrhteBRqi0CSh2uvYNu6hAAA/641' }
      ],
      state: {
        fontIconList: {
          ali: [],
          ele: [],
          awe: [],
          local: [],
        },
      },
      iconlist: [],
      selectitem: "",
      uploadOptions: [
        { value: 1, label: '按更新时间' },
        { value: 2, label: '按上传时间' },
        { value: 3, label: '按图片大小' }
      ],
      formatOptions: [
        { value: 1, label: 'JPG' },
        { value: 2, label: 'PNG' }
      ],
      cateLists: [],//分组树状图
      searchForm: {
        size: 999,
        current: 1,
        original: '',
        type: 10,
        groupId: -1,
      },
      cateId: '',
      groupList: [],
      //filePath: 'https://cdn.rsttest.hnrrcz.com/person/',
      clientY: 349,
      groupHandleShow: false,
      clickactive: '',
      groupRename: '',
      renameHandleShow: false,
      activeImage:null,
      selectImage:{},
      imageClearShow:null,
      addGroupShow:false,
      moveImageId:'',
      moveImageVisible:false,
      moveImageList:[],
      emptyText:'暂无数据',
    }
  },
  computed: {
    ...mapGetters(["project"]),
  },

  methods: {
    materialDialog(){
      this.dialogVisible = false;
      this.groupHandleShow = false
      this.searchForm.groupId = -1
      this.groupList = []
    },
    //重置
    resetPic(){
      this.searchForm.original = ''
      this.getfileList()
    },
    //移动
    movePic(){
       if(Object.keys(this.selectImage).length === 0){
        this.$message({ message: '请先选择需要移动的图片', type: 'warning' });
        return false
       }
       this.moveImageVisible = true
    },
    //确认移动
    moveImageConfirm(){
      let params = {groupId:this.moveImageId,ids:[this.selectImage.id]}
      fileMove({...params}).then(res =>{
        if(res.code == 0){
          this.$message({ message: '移动成功', type: 'success' });
          this.moveDialogClose()
          this.getfileList()
        }
      })
    },
    moveDialogClose(){
      this.moveImageVisible = false
      this.moveImageId = "",
      this.activeImage = null
      this.selectImage = {}
    },
    //鼠标滑过
    ImagemMouseOver(index,item){
      console.log('ImagemMouseOver',index)
      this.renameHandleShow = true
    },
    //删除图片
    deleteImage(item){
      this.$confirm('确认删除后本地将同步删除，如文件已被使用，请谨慎操作！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileDelete([item.id]).then(res => {
          if (res.code == 0) {
            this.getfileList();
            this.$message({ message: '删除成功', type: 'success' });
          }
        })
      });
    },
    ImageItem(index,item){
      this.activeImage = index
      this.selectImage = item
    },
    updateGroupRename() { },

    // 图片名称搜索
    searchPic(bool) {
      this.getfileList()
    },

    // 新增分组操作
    addGroupHandle() {
      this.$refs.addGroupref.addGroupVisible = true
      this.groupHandleShow = false
      this.getfileCateLists()
    },

    // 删除分类
    deleteGroup() {
      this.groupHandleShow = false
      this.$confirm("确认要进行删除操作吗?", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fileGroupDelete(this.searchForm.groupId).then(res => {
          if (res.data) {
            this.$message({ type: "success", message: "删除分组成功", })
            this.addGroupShow = false
            this.getfileCateLists()()
          }
          if (res.code == 1) {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }

        })
      }).catch(() => { })
    },

    // 刷新分组
    groupRefresh() {
      this.getfileCateLists()
    },

    // 分组按钮
    append(event, data) {
      this.groupHandleShow = true
      this.clientY = event.clientY
    },

    // 打开素材库
    open() {
      this.dialogVisible = true;
      if (!this.uploadType || this.uploadType.includes('image')) {
        this.activeName = 'first';
        this.getfileCateLists();
      } else if (this.uploadType.includes('icon')) {
        this.activeName = 'second';
        this.getfileCateLists();
      }
    },

    // 上传图片后刷新数据
    uploadRefresh() {
      this.getfileList()
      this.getfileCateLists()
    },

    // 选择图片分类
    handleCatSelect(item,node) {
      if(!item.id){
        return false
      }
      this.searchForm.groupId = item.id
      this.getfileList()
    },

    // 获取分组数据
    getfileList() {
      fileList({ ...this.searchForm }).then(res => {
        console.log('fileList----res', res)
        if (res.code == 0) {
          this.groupList = res.data.records
        }
      })
    },

    // 获取分组列表
    getfileCateLists() {
      fileCateLists().then(res => {
        if (res.code == 0) {
          let arr = [
            { "id": "-1", "parentId": "-1", "weight": 0, "name": "未分组的"},
            { "id": "", "parentId": "-1", "weight": 0, "name": "自定义分组", "children": []}
          ]
          arr[1].children.push(...res.data)
          this.cateLists = JSON.parse(JSON.stringify(arr))
          this.moveImageList = [{ "id": "", "parentId": "-1", "weight": 0, "name": "未分组的" },...res.data]
        } else {
          this.cateLists = []
        }
      })
    },

    // 上传图片
    uploadPic() {
      this.$refs.uploadRef.open();
      this.getfileCateLists()
    },

    // 选择icon
    selectItem(val, type, index) {
      this.active = index
      if (type == 'el') {
        this.selectitem = 'el-icon-' + val
      } else {
        this.selectitem = val.icon
      }
    },

    Confirm() {
      if (this.uploadType) {
        if (this.activeName == 'first') {
          this.$emit('iconConfirm', { key: 'image', route: this.project.filePath+'/person/'+ this.selectImage.fileName })
        } else {
          this.$emit('iconConfirm', { key: 'icon', route: this.selectitem })
        }
      } else if (this.activeName == 'first') {
        this.$emit('iconConfirm', this.project.filePath+'/person/' + this.selectImage.fileName)
      } else {
        this.$emit('iconConfirm', this.selectitem)
      }

      this.dialogVisible = false
      this.active = null
      this.activeImage = null
      this.addGroupShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.moveDialogLabel{
  width: 100px;
  font-size: 14px;
  margin-right: 5px;
}
.imageClear{
  position: absolute;
  left: 91%;
  top: -7px;
  font-size: 18px;
  color: rgb(0,0,0,0.4);
}
::v-deep.notheader {
  left: -19%;

  .el-dialog__header {
    display: none !important;
  }

  .el-dialog__body {
    padding: 20px !important;

  }

}

::v-deep.remeneHeader {
  position: absolute;
  left: 10px;
  top: 30%;

  .el-dialog__header {
    display: none !important;
  }

  .el-dialog__body {
    padding: 20px !important;
  }
}

::v-deep.icontab {
  .el-tabs__item.is-active {
    background: #006aff;
    color: #fff;
  }
}

.materialTitle {
  display: flex;
  align-items: center;

  .mcenter {
    margin-left: 12px;
    font-size: 12px;
    color: #58aaff;
    cursor: pointer;
  }
}

.grid {
  /* position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
}

.icon-item {
  margin: 20px;
  height: 85px;
  text-align: center;
  width: 100px;
  float: left;
  font-size: 30px;
  color: #24292e;
  cursor: pointer;
}

span {
  display: block;
  font-size: 16px;
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
}

.pic-content {
  width: 100%;
  overflow: auto;

  .cont-left {
    width: 160px;
    border: 1px solid #f5f7f9;

    p {
      padding: 10px;
    }
  }

  .cont-right {
    width: calc(100% - 160px);

    p {
      color: #333333;
      padding: 10px;
      background: rgb(247, 248, 250);
    }

    .right-con {
      height: 364px;
      overflow-y: scroll;

      // -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

    }

    .picture-div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }

}

.upload-pic {
  margin: 15px 0 10px 4px;
  width: 94%;
  text-align: center;
  border: 1px solid #51a7ff;
}

.noDate {}

::v-deep .el-tree-node__content {
  height: 36px !important;
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {

  background-color: rgba(135, 206, 235, 0.2);
  color: #409eff!important;
  font-weight: bold;
}

.custom-tree-node {
  position: relative;
  margin: 10px 0;
  width: 92px;
  padding-right: 8px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;

  text-overflow: ellipsis;
  /* .custom-tree-node-label{
      width: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    } */
}
.disableds {
  cursor: not-allowed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.rightItem {
  margin: 10px;
  width: 118px;
  height: 100%;

  .rightItemImg {
    width: 116px;
    height: 118px;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.noDate {
  margin-top: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.groupHandle {
  p:hover {
    cursor: pointer;
    color: #2e5cf6;
  }
}

.clickactive {
  color: #2e5cf6;
}

.triangle {
  position: absolute;
  left: 40%;
  top: -10%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.slotSuffix {
  transform: translateY(-4px);
  font-size: 12px
}
</style>
