/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2022-02-11 10:08:57
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-11-02 09:10:53
 */

export default {
  baseApi: "/",
  // viewUrl: "http://192.168.0.136:8081/#/",  // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
  viewUrl: "https://dweb.xianguosuyuan.com/#/",  // iframe嵌套的H5地址,H5测试地址
  // viewUrl: "https://dweb.daheyun1314.com/#/",  // iframe嵌套的H5地址,H5正式地址
}