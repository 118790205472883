<template>
    <config-item :label="label">
        <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
            <el-radio-group v-model="value.status">
                <el-radio label="single">
                    <el-input-number v-model.trim="value.size" :min="0" :max="10000" :controls="false"
                        size="small"></el-input-number>
                </el-radio>
                <el-radio label="all">全部</el-radio>
            </el-radio-group>
        </div>
    </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
    name: "SchemaQuantity",
    mixins: [schemaMixin],
    inject: ['mode'],
    props: {
        label: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: {},
        }
    },
    watch: {
        "mValue": {
            immediate: true,
            deep: true,
            handler(newValue) { }
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number {
    width: 80px !important;
}
</style>