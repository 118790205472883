<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-12 17:23:08
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-08-31 20:12:08
-->
<template>
<!--  <el-dialog :visible.sync="dialogVisible"
             :close-on-click-modal="false" title="请选择跳转页面" width="800px"
             :before-close="handleClose"
  >-->

  <el-dialog :visible.sync="dialogVisible"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             title="请选择跳转页面" width="800px"
             @before-close="handleClose"
  >


    <div class="list mb30">
      <div v-for="(tab, i) in list" :key="i" :class="[value.type == tab.type ? 'tab-active' : '']"
           class="tab flex-center" @click="changeTab(tab.type)">
        {{ tab.label }}
      </div>
    </div>

    <!-- 站内链接 -->
    <template v-if="value.type == 'insid'">

      <!--左侧-->
      <div class="pageContent">

          <div class="left" style="height:300px;overflow-x: scroll">
            <div v-for="(item,index) in dataList" :key="index"
                 :class="curClickPage.pagesId==item.pagesId?'cur':'nor'" @click="getPoints(item,false)">
              {{item.name}}
            </div>
          </div>

          <div class="right" >

            <div v-if="curClickPage.linkPoints||curClickPage.dsInitParams">
              <div class="points" v-if="curClickPage.linkPoints">
                <div class="points-tit">
                  选择{{curClickPage&&curClickPage.linkPoints
                &&JSON.parse(curClickPage.linkPoints)[0]
                &&JSON.parse(curClickPage.linkPoints)[0]['title']?JSON.parse(curClickPage.linkPoints)[0]['title']:''}}</div>
                <div class="points-cates">

                 <span v-for="(item,index) in pointsList" :key="index"
                       :class="curPoint.value==item.value?'cate curCate':'cate'" @click="btnPoint(item)">
                   {{item[showName]}}
                 </span>

                </div>
              </div>

              <div v-if="curClickPage.dsInitParams" >
                <!--initData-->
                <!--          <div v-for="(item,index) in initData">
                              </div>
                              -->
                <el-tabs type="border-card">
                  <el-tab-pane :label="item.title" v-for="(item,index) in dsInitParams" :key="index">
                    <div class="points-tit">选择{{item.title?item.title:''}}({{item.required?'必填':'非必填'}})</div>
                    <el-table ref="insidTable" :data="item.dataList" :row-key="getRowKey" class="insidTab"
                              size="mini" stripe max-height="500">
                      <el-table-column align="center" reserve-selection type="selection" width="55">
                        <template slot-scope="scope">
                          <input type="checkBox" :checked="scope.row[item.relationField]==item.curChoose"
                                 @click="btnClickParams(index,scope.row[item.relationField])" />
                        </template>
                      </el-table-column>
                      <!--                    <el-table-column align="center" reserve-selection type="selection" width="100" label="选择">
                                            <template slot-scope="scope">
                                              <input type="checkBox" :checked="value.id.includes(scope.row.pagesId)" @click="btnClickRow(scope.row)"/>
                                            </template>
                                          </el-table-column>-->
                      <el-table-column  :label="items.targetName" :prop="items.source"
                                        class-name="custom-cell"
                                        v-for="(items,indexs) in item.dataMapper" :key="indexs">
                      </el-table-column>

                    </el-table>

                    <el-pagination @size-change="handleSizeChange(index,item,$event)" @current-change="handleCurrentChange(index,item,$event)"
                                   :current-page="item.current" :page-size="item.size" :pager-count="5"
                                   :page-sizes="[10, 20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper"
                                   :total="item.total" v-if="item.needPage==1"
                                   background class="pagina mt15">
                    </el-pagination>


                  </el-tab-pane>

                </el-tabs>



<!--                <div v-for="(item,index) in dsInitParams">

                  <div class="points-tit">选择({{item.required?'必填':'非必填'}})</div>

                  <el-table ref="insidTable" :data="item.dataList" :row-key="getRowKey"  class="insidTab"
                            size="mini" stripe max-height="500">
                                  <el-table-column align="center" reserve-selection type="selection" width="55">
                                        <template slot-scope="scope">
                                          <input type="checkBox" :checked="scope.row[item.relationField]==item.curChoose"
                                                 @click="btnClickParams(index,scope.row[item.relationField])" />
                                        </template>
                                      </el-table-column>
&lt;!&ndash;                    <el-table-column align="center" reserve-selection type="selection" width="100" label="选择">
                      <template slot-scope="scope">
                        <input type="checkBox" :checked="value.id.includes(scope.row.pagesId)" @click="btnClickRow(scope.row)"/>
                      </template>
                    </el-table-column>&ndash;&gt;
                    <el-table-column  :label="items.targetName" :prop="items.source"
                                      class-name="custom-cell"
                                      v-for="(items,index) in item.dataMapper">

                    </el-table-column>

                  </el-table>

                  <el-pagination @size-change="handleSizeChange(index,item,$event)" @current-change="handleCurrentChange(index,item,$event)"
                                 :current-page="item.current" :page-size="item.size" :pager-count="5"
                                 :page-sizes="[10, 20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper"
                                 :total="item.total"
                                 background class="pagina mt15">
                  </el-pagination>

                </div>-->
                <!--     -->
              </div>



            </div>

<!--              <div style="display: flex;justify-content: flex-end" @click="dialogVisible=false">
                <el-button type="primary">确定</el-button>
              </div>-->


          </div>



      </div>


<!--      <el-table ref="insidTable" :data="dataList" :row-key="getRowKey" v-loading="dataListLoading" class="insidTab"
                style="display:none"
                size="mini" stripe max-height="500">
        <el-table-column align="center" reserve-selection type="selection" width="55">
          <template slot-scope="scope">
            <input type="checkBox" :checked="value.id.includes(scope.row.pagesId)" @click="btnClickRow(scope.row)"/>
          </template>
        </el-table-column>
        <el-table-column label="页面名称" prop="name"></el-table-column>
        <el-table-column label="页面跳转点位" prop="linkPoints">

          <template slot-scope="scope">

            <div v-if="scope.row.linkPoints&&scope.row.linkPoints!='null'">

              <div v-if="JSON.parse(scope.row.linkPoints)[0]['type']=='remote'">
                          <span v-for="item in getPoints(JSON.parse(scope.row.linkPoints)[0].url)"
                                @click="btnChoose(item,'remote')"
                                class="normal">
                                {{ item }}
                              </span>
              </div>

              <div
                  v-else-if="scope.row.linkPoints&&JSON.parse(scope.row.linkPoints)[0]&&JSON.parse(scope.row.linkPoints)[0].type=='custom'">
                       <span v-for="(item,index) in JSON.parse(scope.row.linkPoints)[0]['data']"
                             @click="btnChoose(item,'custom')"
                             :class="item.value==curActive?'active':'normal'"
                       >
                {{ item.name }}
              </span>
              </div>

            </div>


          </template>

        </el-table-column>


        <el-table-column label="槽位" prop="sign"></el-table-column>
      </el-table>-->

      <!--      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="queryForm.current" :page-size="queryForm.size" :pager-count="5"
                           :page-sizes="[10, 20, 50, 100, 200]" layout="total, sizes, prev, pager, next, jumper"
                           :total="totalPage"
                           background class="pagina mt15">
            </el-pagination>-->
    </template>

    <!-- 站外链接 -->
    <template v-if="value.type == 'link'">
      <el-form ref="form" :model="value" label-width="80px">
        <el-form-item label="跳转方式" prop="mode">
          <el-radio-group v-model="value.mode">
            <el-radio label="1">H5跳转</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" prop="mode">
          <el-input v-model="value.id" placeholder="请输入h5跳转链接" size='mini' style="width:250px"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <!-- 跳转小程序 -->
    <template v-if="value.type == 'applet'">
      <el-form ref="form" :model="value" label-width="80px">
        <el-form-item label="跳转方式:" prop="mode">
          <el-radio-group v-model="value.mode">
            <el-radio label="1">常规跳转</el-radio>
            <el-radio label="2">微信半屏小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="App ID:" prop="mode">
          <el-input v-model="value.id" placeholder="请输入小程序APPID" size='mini' style="width:250px"></el-input>
        </el-form-item>
        <el-form-item label="路径:" prop="mode">
          <el-input v-model="value.data.jump" placeholder="请输入跳转链接" size='mini' style="width:250px"></el-input>
        </el-form-item>

      </el-form>
    </template>

  </el-dialog>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";
import {getFormPage} from "@/api/project";
import {getCategoryList,datasourcePage,getCategoryListPost} from "@/api/easyApp";
import {deepClone} from "../../../js/utils";

export default {
  name: "JumpDialogView",
  inject: ["value"],
  data() {
    return {
      list: [
        {label: "站内链接", type: "insid"},
        {label: "站外链接", type: "link"},
        {label: "跳转小程序", type: "applet"},
        {label: "不跳转", type: undefined},
      ],
      dataList: [],
      totalPage: 0,
      dialogVisible: false,
      dataListLoading: false,
      queryForm: {
        aid: '',
        size: 100,
        current: 1,
      },
      curActive: '',//点击过的点位
      pointsList:[],//点位列表
      curClickPage:{
        pagesId:''
      },
      clickId:'',
      showName:'',
      curPoint:{
        value:''
      },
      initData:[],
      dsInitParams:[],
    };
  },

  props: {
    linkList:{
      default: () => ([]),
    }
  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),
    customPages() {
      return this.project.pagesEntityList;
    },
  },
  async created() {
  // console.log("选择跳转链接选择跳转链接选择跳转链接选择跳转链接选择跳转链接选择跳转链接选择跳转链接选择跳转链接");
   /* let res1 = await getRuleList([this.tagCategory]);
    this.decRule=JSON.parse(res1.data[0].ruleData);*/
   // console.log("==this.linkList==:",this.linkList);
    // console.log(this.customPages);
    // console.log(this.value);
/*    if (this.customPages && this.customPages.length > 0 && this.value.id && this.value.type == 'insid') {
      let index = this.customPages.findIndex(a => a.pagesId == this.value.id);
      //console.log(index);
      if (index == -1) {
        this.value.data = {};
        this.value.id = "";
      }
    }*/

  },

  methods: {
  /*  getName(decRule,target) {
      console.log("indexindx:",decRule,target)
      let index = decRule.findIndex((item) => item.sign == target);
      if (index != -1) {
        return decRule[index].name;
      } else {
        return "";
      }

    },*/
    //关闭弹框
    handleClose(){
       console.log("关闭弹框关闭弹框关闭弹框关闭弹框");
       //console.log("this.dsInitParams:",this.dsInitParams);
       console.log("this.valuethis.valuethis.valuethis.valuethis.value",this.value,this.dsInitParams);

       //需要判断下必填参数必须选完
       if(this.dsInitParams){

         for(const i in this.dsInitParams){
           let index=this.dsInitParams.findIndex((item) => item.curChoose!=null&&item.curChoose!="");
           if(this.dsInitParams[i].required&&!this.dsInitParams[i].curChoose&&index==-1){
             this.$message({
               message: '请选择一个必填条件',
               type: 'error'
             });
             return
           }

         }

         this.dialogVisible=false;
       }
       else{
         this.dialogVisible=false;
       }
      //this.$parent.$emit('success', this)
       this.$forceUpdate();

    },
    btnChoose(item) {
      this.curActive = item.value;
      console.log("item:", item);
    },
    //获取点位列表
    async getPoints(item,init) {

     console.log("linkList:",this.linkList);
     console.log("item:",item);

     //初始化的时候不用处理东西
     if(!init){
       let index=-1;
       if(this.value.id){
         index = this.value.id.indexOf(item.pagesId);
       }

       if (index !== -1) {

         this.value.data = {};
         this.value.id = "";

       } else {
         this.value.data = item;
         this.value.id = item.pagesId;
       }

       this.value.linkPoint=null;
       this.value.initParams=[];
       this.curPoint={value:''};

     }

      this.curClickPage=item;

      if(item.linkPoints){
        console.log("JSON.parse(item.linkPoints)[0]:",JSON.parse(item.linkPoints)[0]);

        this.clickId=JSON.parse(item.linkPoints)[0].sign;
        this.showName=JSON.parse(item.linkPoints)[0].name;

        if(JSON.parse(item.linkPoints)[0]['type']=='remote'){

          let data = {
            url: JSON.parse(item.linkPoints)[0].url
          }

          console.log("JSON.parse(item.linkPoints)[0].url:",JSON.parse(item.linkPoints)[0].url);

          let res=await getCategoryList(data);
          let list=[];
          for(const i in res.data){
            let obj={
              sign:this.clickId,
              value:res.data[i][this.clickId],
              name:res.data[i][this.showName]
            }
            list.push(obj);
          }
          //  this.pointsList=res.data;


          if(JSON.parse(item.linkPoints)[0].groupModel){
            let obj={};
                 obj['sign']=this.clickId;
                 obj['value']='123456';
                 obj['name']='全部';
            list.unshift(obj);
          }
          this.pointsList=list;

          console.log("this.pointsList:",this.pointsList);

        }
        else{
          this.pointsList=JSON.parse(item.linkPoints)[0]['data'];
        }

        if(init){
          this.curPoint=deepClone(this.value.linkPoint);
        }

      }
      else{
        this.pointsList=[];
      }


      //得到初始化参数 获取数据
      if(item.dsInitParams){
        let dsInitParams=JSON.parse(item.dsInitParams);

        console.log("==dsInitParams==:",dsInitParams);
        let code=[];
        let initList=[];
        //let alldata

        for(const i in dsInitParams){
          dsInitParams[i].relationParams=JSON.parse(dsInitParams[i].relationParams);

          console.log("relationParams:",dsInitParams[i].relationParams);
          // let dsInitParamsCode=[];
          for(const j in dsInitParams[i].relationParams){
            code.push(dsInitParams[i].relationParams[j]['relationDS']);

           console.log("dsInitParams[i].relationParams[j]:",dsInitParams[i].relationParams[j]);
           const dataMapperIndex= this.linkList.findIndex((item) => item.code == dsInitParams[i].relationParams[j]['relationDS']);

           let dataMapper=[];
           if(dataMapperIndex!=-1){
             dataMapper=this.linkList[dataMapperIndex].dataMapperList[0]['configContent'];
             dataMapper=JSON.parse(dataMapper);
           }
           console.log("dataMapper:",dataMapper);

            let obj={
              code:dsInitParams[i].relationParams[j]['relationDS'],
              componentId:dsInitParams[i].componentId,
              componentIdCode:dsInitParams[i].componentId+'*'+dsInitParams[i].relationParams[j]['relationDS'],
              dataMapper:dataMapper,
             // decRule:dsInitParams[i].decRule,
              name:dsInitParams[i].relationParams[j]['name'],//传递的
              relationField:dsInitParams[i].relationParams[j]['relationField'],//勾选的
              required:dsInitParams[i].relationParams[j].required,
              current:1,
              size:10,
              dataList:[],
              pages:0,
              total:0,
              curChoose:'',
              url:'',
              title:'',
              needPage:0
            }
            initList.push(obj);
           // dsInitParamsCode.push(dsInitParams[i].relationParams[j]['relationDS']);
          }
         // this.dsInitParams[i]['dsInitParamsCode']=dsInitParamsCode.toString();
        }

        //展示字段 点击字段 传递字段

        //this.dsInitParams=initList;
     //   console.log("this.dsInitParams:",this.dsInitParams);

        let data={
          codes:code.toString(),
          current:1,
          size:100
        }
        let res=await datasourcePage(data);
        let records=res.data.records;

       // console.log("records:",records)

        for(const i in initList){

          let obj={
            current:1,
            size:10,
            dataList:[],
            pages:0,
            code:records[i].code,
            total:0
          }

          let result=await this.getInitData(obj,records[i].url,records[i].method);

          console.log("records[i]:",records[i].name);
          initList[i].needPage=records[i].needPage;
          if(records[i].needPage==1){
            initList[i].dataList=result.records;
            initList[i].pages=result.pages;
            initList[i].total=result.total;
          }
          else{
            initList[i].dataList=result;
          }


          initList[i].url=records[i].url;
          initList[i].title=records[i].name;

          initList[i].method=records[i].method;
          //initList[i].pages=result.pages;


        //  this.initData.push(obj);
        }

        console.log("initList:",initList);

        this.dsInitParams=initList;

        if(init&&this.value.initParams){
          //curChoose:'',
          //initParams

          for(const i in this.value.initParams){
            //this.value.initParams.findIndex((item) => item.componentId == componentId);
           let index=this.dsInitParams.findIndex((item) => item.componentId == this.value.initParams[i].componentId);
           if(index!=-1){
             this.dsInitParams[index].curChoose=this.value.initParams[i][this.value.initParams[i]['relationField']];
           }
          }

        }

        console.log("this.dsInitParams:",this.dsInitParams);

      }
      else{
        this.dsInitParams=[];
      }

    },
   async getInitData(obj,url,method){
      if(method=='post'||method=='POST'){
           let data={
             current:obj.current,
             size:obj.size,
             url:url
           }

           let res=await getCategoryListPost(data);

           return res.data;
      }
      else if(method=='get'||method=='GET'){
        let data={
          current:obj.current,
          size:obj.size,
          url:url
        }

        let res=await getCategoryList(data);

        return res.data;
      }

    },
    //点击点位跳转
    btnPoint(item){
      this.curPoint=item;

      this.value.linkPoint={
        ...item,
        id:JSON.parse(this.curClickPage.linkPoints)[0]['id']
      }

      //console.log("this.curClickPage:",JSON.parse(this.curClickPage.linkPoints)[0]['id']);
      //console.log("item",item,this.clickId,this.showName);
    },
    //点击参数
    btnClickParams(index,id){
      console.log("index:",index);
      console.log("id:",id);

      const componentId=this.dsInitParams[index].componentId;
      let index1=-1;
      if(this.value.initParams){
        index1 = this.value.initParams.findIndex((item) => item.componentId == componentId);
      }
      else{
        this.value.initParams=[];
      }

      this.dsInitParams[index].curChoose=id;
      const obj={
        componentId:this.dsInitParams[index].componentId,
        relationField:this.dsInitParams[index].relationField,//前置
        name:this.dsInitParams[index].name,//接收
      }
      obj[this.dsInitParams[index].relationField]=id;

      if(index1!=-1){
        //this.componentEntityList=this.project.pagesEntityList[index].componentEntityList;
        this.value.initParams[index1]=deepClone(obj);
        //this.value.initParams.push(obj);
      }
      else{
        this.value.initParams.push(obj);
      }

      console.log("this.dsInitParams:",this.dsInitParams);

      /*this.value.initParams.push({
      })*/


    },
    // 开启
    open() {
      console.log("开启开启开启开启开启this.value:",this.value);
      this.dialogVisible = true;
      this.getDataList();
    },
    // 获取table 列表
    getDataList() {
      this.queryForm.aid = this.project.aid;
      getFormPage(this.queryForm).then(res => {
        this.dataList = res.data.records;
        this.totalPage = res.data.total;

        if(this.value&&this.value.type=='insid'){
          let index=this.dataList.findIndex(a => a.pagesId == this.value.id);
          if(index!=-1){
            this.getPoints(this.dataList[index],true)
          }
          else{
            this.value.data = {};
            this.value.id = "";
            this.value.linkPoint=null;
            this.value.initParams=[];
            this.curPoint={value:''};
          }


        }
        else{
          this.value.data = {};
          this.value.id = "";
          this.value.linkPoint=null;
          this.value.initParams=[];
          this.curPoint={value:''};
        }


      }).catch((err) => {
        this.$message.error(err.msg);
      });
    },
    // 行数据的 Key
    getRowKey(row) {
      return row.pagesId;
    },

    // 选中
    btnClickRow(row) {

      let index = this.value.id.indexOf(row.pagesId);
      if (index !== -1) {
        this.value.data = {};
        this.value.id = "";
      } else {
        this.value.data = row;
        this.value.id = row.pagesId;
      }

    },

    // 请选择跳转页面
    changeTab(type) {
      if (this.value.type == type) return;

      this.$set(this.value, "type", type);
      this.$set(this.value, "id", "");
      this.$set(this.value, "data", {});
      this.$set(this.value, "linkPoint", null);
      this.$set(this.value, "initParams", []);

      if (type == 'link' || type == 'applet') {
        this.$set(this.value, "mode", "1");
      } else {
        this.$set(this.value, "mode", "");
      }

      if (type == 'insid') {
        this.getDataList();
      }

    },

    // 每页数
    handleSizeChange(index,item,val) {

      console.log("每页数item:",index,item,"val:",val)

      /*this.queryForm.size = val;
      this.queryForm.current = 1;*/
      this.getList(index,item,val,'size');

    },

    // 当前页
    handleCurrentChange(index,item,val) {
      console.log("当前页item:",index,item,"val:",val)
      //this.queryForm.current = val;
      this.getList(index,item,val,'current');


    },
    async getList(index,item,val,cate){

      if(cate=='size'){
        this.dsInitParams[index].size=val;
        this.dsInitParams[index].current=1;
      }
      else{
        this.dsInitParams[index].current=val;
      }


      if(item.method=='post'||item.method=='POST'){
        let data={
          current:this.dsInitParams[index].current,
          size:this.dsInitParams[index].size,
          url:item.url
        }

        let res=await getCategoryListPost(data);

        this.dsInitParams[index].dataList=res.data.records;
        this.dsInitParams[index].pages=res.data.pages;
        this.dsInitParams[index].total=res.data.total;

       /* let result=await this.getInitData(obj,records[i].url,records[i].method);
        initList[i].dataList=result.records;
        initList[i].url=records[i].url;
        initList[i].method=records[i].method;
        //initList[i].pages=result.pages;
        initList[i].pages=result.pages;
        initList[i].total=result.total;*/



      }
      else if(item.method=='get'||item.method=='GET'){
        let data={
          current:this.dsInitParams[index].current,
          size:this.dsInitParams[index].size,
          url:item.url
        }

        let res=await getCategoryList(data);
        this.dsInitParams[index].dataList=deepClone(res.data.records);
        this.dsInitParams[index].pages=res.data.pages;
        this.dsInitParams[index].total=res.data.total;

      }


    }


  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variable";


.pageContent{
  display: flex;
}

.left{
  width:160px;
  font-size:14px;
  border-right:1px solid #ededed;
  .cur{
    background-color: rgba(239,250,255,1);
    color: rgba(56,148,255,1);
    height:50px;
    line-height:50px;
    white-space: nowrap;       /* 防止文本换行 */
    overflow: hidden;          /* 隐藏溢出的内容 */
    text-overflow: ellipsis;   /* 显示省略符号来代表被修剪的文本 */
    text-indent:10px;
  }
  .nor{
    height:50px;
    line-height:50px;
    color:#333;
    white-space: nowrap;       /* 防止文本换行 */
    overflow: hidden;          /* 隐藏溢出的内容 */
    text-overflow: ellipsis;   /* 显示省略符号来代表被修剪的文本 */
    text-indent:10px;
  }
}

.right{
  flex:1;
  padding-left: 20px;
}





.list {
  display: flex;

  .tab {
    width: 100px;
    height: 30px;
    color: #979797;
    border: solid 1px #ebedf0;
    cursor: pointer;
  }

  .tab-active {
    color: $color-theme;
    border: solid 1px $color-theme;
    background: #e0edff;
  }
}

.first-leve {
  width: 100%;
  height: 33px;
  line-height: 33px;
  padding: 0 20px;
  cursor: pointer;
}

.active {
  color: #ffffff;
  background: rgb(0, 106, 255);
}

.active-Scon {
  color: rgb(56, 148, 255);
  background: rgb(239, 250, 255);
}

::v-deep .insidTab .el-table__header-wrapper .el-table__header .el-checkbox {
  // 找到表头那一行，然后把里面的复选框隐藏掉
  display: none !important;
}

.el-cascader-panel {
  width: 140px !important;
  border: none !important;
}

.el-cascader-menu__list {
  width: 140px !important;
  overflow: hidden;
}

::v-deep .el-cascader-menu {
  min-width: 140px !important;
}


.active {
  color: #fff;
  background: #409fee;
  padding: 3px 5px;
  border-radius: 3px;
  display: inline-block;
}

.normal {
  color: #101010;
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 10px;
  display: inline-block;
}
.cate{
  background:#f3f4f6;
  font-size:14px;
  color:#333;
  padding:10px 20px;
  margin-bottom:15px;
  margin-right:15px;
  border-radius:3px;
  display: inline-block;
}

.curCate{
  color: #fff!important;
  background: #409fee!important;
}

.points-tit{
  margin-bottom:20px;
  color: rgba(16,16,16,1);
  font-size: 14px;
  font-family: PingFangSC-regular;
}
.insidTab .custom-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height:20px;
}
</style>
