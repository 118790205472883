<!--
 * @Description: 页面跳转选择组件
 * @Autor: WangYuan
 * @Date: 2021-08-12 11:06:37
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 19:38:10
-->

<template>
  <div v-show="isShow" :class="{ pt10: padd.includes('top'), pb10: padd.includes('bottom') }"
    class="flex pl10 pr10 bg-white">
    <div class="w80 f13 f-grey pt10">{{ label }}</div>

    <div style="flex: 1;">
      <!-- 选择跳转到的页面 -->
      <div v-if="!mValue.id" :style="'float:' + place" class="flex col-center h32">
        <div class="f14 jump-button" @click="open">选择跳转到的页面</div>
      </div>

      <!-- 修改 -->
      <div v-else :style="'float:' + place" class="flex col-center h32" @click="open">
        <div class="tag">{{ pageName }}</div>
        <div class="f14 jump-button">修改</div>
      </div>
    </div>

    <jump-dialog-view ref="jump" :linkList="linkList" @success="getPageName"></jump-dialog-view>


  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { mapGetters } from "vuex";
import JumpDialogView from "@/custom-schema-template/components/SchemaJump/JumpDialog";
import {getDatasourceList} from "../../../api/easyApp";

export default {
  name: "SchemaJump",
  components: { JumpDialogView },
  mixins: [schemaMixin],

  provide() {
    return {
      value: this.value,
    };
  },

  data() {
    return {
      linkList:[],//跳转链接
    };
  },

  props: {
    value: {
      default: () => ({}),
    },
    place: {
      default: () => ({}),
    },
    padd: {
      default: "left,right,top,bottom",
    },
    isShow: {
      default: true,
    }
  },

  watch: {
    "mValue": {
      immediate: true,
      deep: true,
      handler(newValue) {
        console.log("newValuenewValuenewValuenewValue:",newValue);
      }
    }
  },
  computed: {
    ...mapGetters(["project", "fixedPages"]),
    pageName() {
      let page = null;

      console.log("pageNamepageNamepageNamepageName:",this.mValue);


      switch (this.mValue.type) {
        case "insid":
          page = { name: this.mValue.data?.name };
          break;

        case "applet":
          page = { name: this.mValue.mode == '1' ? '跳转小程序' : '微信半屏小程序' };
          break;

        case "link":
          page = { name: "外链h5" };
          break;
        default:
          break;
      }
      return page.name;
    },
  },

  methods: {
    // 打开
    async open() {

      console.log("value:",this.mValue);

      console.log("打开打开打开打开打开打开打开打开打开打开打开打开打开打开");
      let data = {
        tags: 'linkList'
      }

      let res = await getDatasourceList(data);
      this.linkList = res.data;
      this.$refs.jump.open();
    },
    getPageName(){
      /*console.log("==getPageName==");
      console.log("this.mValuethis.mValuethis.mValuethis.mValuethis.mValue:",this.mValue);*/
    }
  },
};
</script>

<style lang="scss" scoped>
.tag {
  background: rgb(236, 245, 255);
  color: rgb(64, 158, 255);
  border: 1px solid;
  margin: 0 5px;
  max-width: 90px;
  font-size: 12px;
  padding: 5px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 3px;
}

.jump-button {
  border-radius: 3px;
  background: #409eff;
  color: white;
  padding: 4px 7px;
  text-align: center;
}
</style>
