<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <!--  <config-item v-show="isShow" :label='label'>-->

  <div>
    <div style="display: flex;align-items: center" class="mb20">

      <div class="w80 f13 f-grey pl12 mr10">
        {{ label }}
      </div>
      <div style="flex:1;">
        <el-select style="width: 100%" v-model="mValue.tagSource" size="small" @change="change">
          <el-option v-for="(item,index) in tagList" :key="index" :label="item.name" :value="item.url" />
        </el-select>
      </div>
    </div>
    <!--控制展示内容-->
    <div v-if="mValue.isConDisContent" style="display: flex;">
      <div class="w80 f13 f-grey pl12 mr10">{{ mValue.title }}</div>

      <div style="flex:1">
        <el-checkbox-group v-model="mValue.displayContent">
          <el-checkbox :label="item.target" :value="item.target" v-for="(item, index) in dataList" v-if="item.target!='keyword'"
            :key="item.sign || index">
            {{ item.targetName }}
          </el-checkbox>
        </el-checkbox-group>

      </div>


    </div>

  </div>


  <!--  </config-item>-->
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { comDSList, getDatasourceList, getRuleList } from "../../../api/easyApp";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "SchemaTagSource",
  mixins: [schemaMixin],
  props: {
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    tagCategory: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: {}
    }
  },

  inject: ['mode'],

  data() {
    return {
      defaultOptions: {
        trueLabel: "显示",
        falseLabel: "隐藏",
      },
      tagList: [],
      valueNew: '',
      dataList: [],
      decRule: [],
      searchRuleSign: ''
    };
  },
  computed: {
    ...mapGetters(["project"]),

  },
  /*  watch: {
      valueNew: {
        immediate: true,
        deep:true,
        handler(newVal,oldVal) {
          //this.value = this.valueNew;
        },
      },
    },*/

  async created() {

    console.log("this.mValue:", this.mValue);
    /*let data={
      tags:this.tagCategory
    }
    let res=await comDSList(data);
    this.tagList=res.data;*/
    let res1 = await getRuleList([this.tagCategory]);
    this.decRule = JSON.parse(res1.data[0].ruleData);

    this.mValue.decRule = JSON.parse(res1.data[0].ruleData);

   // let index = this.decRule.findIndex((item) => item.sign == 'keyword');

     /*if(this.mValue.isSupportSearch==true&&index!=-1){
       this.searchRuleSign=this.decRule[index].name;
     }*/


    // console.log("this.mValue:",this.mValue);

    let data = {
      tags: this.tagCategory,
      sceneType: this.project.sceneType + ',GGXX'
    }

    // console.log("data:",data,this.project);

    let res = await getDatasourceList(data);
    this.tagList = res.data;

    console.log("this.tagList ", this.tagList);

    if (this.mValue.tagSource) {
      let index = this.tagList.findIndex((item) => item.url == this.mValue.tagSource);

      console.log("index:", index);

      if (index != -1) {
        let dataList = JSON.parse(this.tagList[index].dataMapperList[0].configContent);
        this.dataList = dataList.filter((item) => item.sign!='keyword');

        console.log("this.dataListthis.dataListthis.dataList:",this.dataList);

        this.mValue.dataMapper = JSON.parse(this.tagList[index].dataMapperList[0].configContent);
        console.log("this.searchRuleSign:", this.searchRuleSign);

        if (this.mValue.isSupportSearch == true) {
          //this.mValue.dataMapper
          let index = this.mValue.dataMapper.findIndex((item) => item.target =='keyword');

          console.log("indexindexindexindexindexindex:",index);

          if(index!=-1){
            this.mValue.realSearchSign=this.mValue.dataMapper[index].source;
          }

        }


        console.log("this.mValue:", this.mValue);
      }
    } else {
      this.mValue.tagSource = this.tagList[0].url;
      this.mValue.dataMapper = JSON.parse(this.tagList[0].dataMapperList[0].configContent);
      this.mValue.relationParams = this.tagList[0].relationParams;
      let dataList = JSON.parse(this.tagList[0].dataMapperList[0].configContent);
      this.dataList = dataList.filter((item) => item.sign!='keyword');


      console.log("this.searchRuleSign:", this.searchRuleSign);

      if (this.mValue.isSupportSearch == true) {
        //this.mValue.dataMapper
        let index = this.mValue.dataMapper.findIndex((item) => item.target =='keyword');
        console.log("indexindexindexindexindexindex:",index);

        if(index!=-1){
          this.mValue.realSearchSign=this.mValue.dataMapper[index].source;
        }

      }

      let displayContent = [];

      console.log("this.mValue.dataMapper:", this.mValue.dataMapper);

      for (const i in this.mValue.dataMapper) {
        displayContent.push(this.mValue.dataMapper[i].target);
      }
      this.mValue.displayContent = displayContent;
    }

    //如果支持搜索 根据搜索字段换取到真正的字段


    console.log("this.mValuethis.mValue:", this.mValue);

  },
  methods: {
    getName(target) {
      let index = this.decRule.findIndex((item) => item.sign == target);

      if (index != -1) {
        return this.decRule[index].name;
      } else {
        return "";
      }

    },
    change(val) {
      console.log("val:", val);
      let index = this.tagList.findIndex((item) => item.url == val);
      console.log("index:", index);
      if (index != -1) {
        let dataList = JSON.parse(this.tagList[index].dataMapperList[0].configContent);
        this.dataList = dataList.filter((item) => item.sign!='keyword');

        this.mValue.dataMapper= JSON.parse(this.tagList[index].dataMapperList[0].configContent);
        if(this.mValue.isSupportSearch==true){
          //this.mValue.dataMapper
          let index1 = this.mValue.dataMapper.findIndex((item) => item.target =='keyword');
          console.log("index1index1index1:",index1);
          if(index1!=-1){
            this.mValue.realSearchSign=this.mValue.dataMapper[index1].source;
          }
        }

        this.$forceUpdate();
        let displayContent = [];
        for (const i in this.mValue.dataMapper) {
          displayContent.push(this.mValue.dataMapper[i].target);
        }
        this.mValue.displayContent = displayContent;
        this.mValue.relationParams = this.tagList[index].relationParams;

      }
      else {
        this.mValue.displayContent = [];
        this.mValue.relationParams = '';
        this.mValue.realSearchSign = '';
      }

      console.log("this.dataList:", this.dataList);

    }
  }

};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  /*no*/

  .wrap-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }
}
</style>