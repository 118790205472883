<template>
  <div>
    <el-dialog title="添加分组" :visible.sync="addGroupVisible" width="680px">
      <el-form :model="groupform" :rules="rules" ref="groupform" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-form-item label="所在分组" :rules="{ required: true, message: '必填项' }" style="width: 580px;">
            <el-select v-model="groupform.pid" disabled filterable placeholder="自定义分组" style="width: 100%;">
              <el-option label="自定义分组" value="-1"> </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="分组名称" prop="name" style="width: 580px;">
            <el-input maxlength="20" v-model="groupform.name" placeholder="请输入分组名称"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addGroupCancle" size="mini">取 消</el-button>
        <el-button type="primary" @click="addGroupConfirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {fileGroupAdd } from "@/api/material"
export default {
   name:'addGroup',
   data() {
        return {
            addGroupVisible:false,//添加分组显示与隐藏
            groupform:{
                type:10,
                pid:'-1',
                name:''
            },
            rules:{
                name:[ { required: true, message: '请输入分组名称', trigger: 'blur' }]
            },
        }
    },
    mounted() {
    },
    methods: {
        //添加分组确定
        addGroupConfirm(){
            this.$refs["groupform"].validate((valid) => {
                console.log('3333333333333333333',valid)
                if (valid) {
                    fileGroupAdd({...this.groupform}).then(res =>{
                        console.log('fileGroupAdd',res)
                        if(res.code == 0){
                            this.$message({type: "success",message:'新增分组成功'})
                            this.groupform = {}
                            this.addGroupVisible = false
                            this.$emit('groupRefresh')
                        }else{
                            this.$message({type: "warning",message:'新增分组失败'})
                        }
                    })
                }
            })
        },
        addGroupCancle(){
            this.addGroupVisible = false;
            this.groupform = {}
        },
    }
}
</script>
<style lang=""></style>
