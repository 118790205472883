<!-- @Description: 上传图片 -->
<template>
  <div>
    <el-dialog title="上传图片" :visible.sync="uploadVisible" width="680px">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm">
        <el-row>
          <el-form-item label="所在分组" :rules="{ required: true, message: '请选择图片分组' }">
            <div style="display: flex;align-items: center;">
              <el-select v-model="form.groupId" filterable placeholder="请选择所在分组" style="width: 100%;">
                <el-option v-for="item in cateLists" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
              <el-button type="text" class="ml12" @click="addGroup()">添加分组</el-button>
              <el-button type="text" @click="clickRefresh">刷新</el-button>
            </div>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="本地图片" :rules="{ required: true, message: '请选择图片' }">
            <el-upload class="avatar-uploader" action :show-file-list="false" :http-request="uploadFile" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="project.filePath+'/' + imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span class="tips">支持.jpg、.png格式，最多1张，单个图片不超过10MB。</span>
          </el-form-item>
        </el-row>
        <!-- <el-row>
          <el-form-item label="图片名称" style="width: 530px;">
            <el-input v-model="form.name" placeholder="请输入图片名称"></el-input>
          </el-form-item>
        </el-row> -->
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="uploadCancle" size="mini">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit" size="mini">确 定</el-button>
      </span> -->
    </el-dialog>
    <addGroup ref="addGroupref" :groupList="groupList" @groupRefresh="groupRefresh"></addGroup>
  </div>
</template>
<script>
import global from '@/config/global';
import { getDictItem } from "@/api/enrollStudent";
import { fileCateLists,fileGroupAdd } from "@/api/material"
import addGroup from "./addGroup"
import {mapGetters} from "vuex";
export default {
  components: {
    addGroup
  },
  data () {
    return {
      uploadVisible: false,
      imageUrl:"",
      form: {
        type: 10,
        groupId:''
      },
      value:'',
      cateLists:[],
      groupList:[],
     // filePath:'https://cdn.rsttest.hnrrcz.com/',
    }
  },
  mounted() {
    this.getfileCateLists()
  },
  computed: {
    ...mapGetters(["project"]),
  },
  methods: {

    open() {
      this.uploadVisible = true;
       this.form.groupId = ""
    },
    clickRefresh(){
      this.form.group == ''
    },
    groupRefresh(){
      this.getfileCateLists()
    },
    uploadFile(item){
      let file = item.file
      getDictItem('cdnAndHttp').then(res => {
          const value = res.data[0].value;
          Vue.prototype.$POST.upload(global.baseApi + 'admin/sys-file/upload', ({
            file, dir: 'person',type:10,groupId:this.form.groupId
          }), {
            baseURL: '',
            timeout: 20000,
          }).then(res => {
            console.log('2222222222',res.data)
            if(res.code == 0){
              this.imageUrl = res.data.cdnUrl
              this.$message.success('图片上传成功');
              this.uploadCancle()
              this.form.groupId = ""
              this.$emit('uploadRefresh')
            }else{
              this.$message.error('图片上传失败');
            }
          })
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'|| 'image/jpg' || 'image/png'|| 'image/PNG'|| 'image/JPEG'||'image/JPG';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    },
    //提交
    // uploadSubmit(){
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       // this.form.imageUrl = this.imageUrl[0]
    //       this.$emit('uploadRefresh')
    //       console.log('2222222222222222222222222222222',this.form)
    //     } else {

    //     }
    //   });
    // },
    uploadCancle(){
      this.uploadVisible = false
      for (let key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          if (key != "") {
            this.form[key] = "";
          }
        }
      }
      this.imageUrl = ""
    },
    //添加分组
    addGroup(){
      this.$refs.addGroupref.addGroupVisible = true
    },
    //获取分组列表
    getfileCateLists(){
      fileCateLists().then(res =>{
        if(res.code == 0){
          let arr = { "id": "-1", "parentId": "-1", "weight": 0, "name": "未分组的" };
          this.cateLists = [arr,...res.data]
        }else{
          this.cateLists = []
        }
      })
    },

    handleRemove() {},
    // handleFileChange() {},
  }
}
</script>

<style lang="scss" scoped>

.ml12{
  margin-left: 12px;
}
.tips{
  margin-top: 12px;
  font-size: 12px;
  color: #999;

}
.avatar-uploader{
  border: 1px dashed #DCDFE6;
  border-radius: 6px;
  cursor: pointer;
  width: 100px;
  height: 100px;
}
.avatar-uploader :hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
