/*
 * @Description: 项目管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-10 20:15:44
 */
import request from '@/utils/request'

/**
 * 查询项目列表
 */
export const getProjectList = async data =>
  // request({ url: 'project/getByList', method: 'POST', data })
  request({ url: 'easyapp/shop/getListBy', method: 'POST', data })

/**
 * 根据id查询项目详情
 */
export const getProjectById = async data =>
  request({ url: 'project/getById', method: 'POST', data })

export const geteasyappById = async data =>
    request({ url: 'easyapp/shop/'+data, method: 'get', data })



/**
 * 新增项目
 */
export const addProject = async data =>
  // request({ url: 'project/add', method: 'POST', data })
  request({ url: 'easyapp/shop', method: 'POST', data })

/**
 * 编辑
 */
export const editProject = async data =>
  // request({ url: 'project/edit', method: 'POST', data })
  request({ url: 'easyapp/shop', method: 'POST', data })

/**
 * 删除
 */
export const delProject = async data =>
  request({ url: 'project/delete', method: 'POST', data })

/**
 * 查询项目模板
 */
export const getModelList = async data =>
  request({ url: 'project/getModelList', method: 'POST', data })

/**
 * 封面上传
 */
export const uploadCover = async data =>
  request({ url: 'upload', method: 'POST', data })

/**
 * 生成小程序码
 */
export const createWXcode = async data =>
  request({ url: 'project/getWXQr', method: 'POST', data })

/**
 * 页面新增保存
 */
export const pageSave = async data =>
request({ url: 'easyapp/pages', method: 'POST', data })

/**
* 页面修改
*/
export const shopUpdate = async (data) => 
request({ url: "easyapp/pages", method: "put", data })

/**
* 页面列表
*/
export const getFormPage = async (params) =>
request({ url: "easyapp/pages/getPage", method: "get", params })

/**
* 页面删除
*/
export const delFormPage = async data =>
  request({ url: 'easyapp/pages', method: 'DELETE', data })


// export const getObjBySceneType = async (params) => request({url: "/form/scene/getBySceneType/" + params, method: "get"})

// 获取分类场景列表
export const getSceneListByType = async (dictType) => request({ url: `form/scene/getBySceneType/${dictType}`, method: "get" })


//获取字典
export const getDicts = async (params) =>
    request({ url: "admin/dict/type/"+params.type, method: "get", params})

