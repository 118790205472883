/*
 * @Description: template 类型组件对应
 * @Autor: WangYuan
 * @Date: 2021-10-18 11:47:02
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-18 20:50:11
 */
export default {
    number: "SchemaNumber",
    backSetting: "SchemaBackSetting",
    quantity: "SchemaQuantity",

    string: "SchemaString",
    select: "SchemaSelect",
    checkbox: "SchemaCheckbox",
    radio: "SchemaRadio",
    listing: "SchemaListing",
    category: "SchemaCategory",
    switch: "SchemaSwitch",
    color: "SchemaColor",
    upload: "SchemaUpload",
    jump: "SchemaJump",
    time: "SchemaTime",
    cube: "SchemaCapCube",
    goods: "SchemaGoods",
    object: "SchemaObject",
    array: "SchemaArray",
    arrayImg: "SchemaArrayImage",
    selectImg: "SchemaSelectImage",
    stringTab: "SchemaStringTab",
    textarea: "SchemaTextarea",
    viewMore: "SchemaViewMore",
    search: "SchemaSearch",
    hotWordlist: "SchemaHotWordList",
    example: "SchemaExample",
    searchJump: "SchemaSearchJump",
    selectarr: "SchemaSelectArr",
    isOpen: 'SchemaIsOpen',
    word: 'SchemaWord',
    tagSource: 'SchemaTagSource',
    tagSourceNew: 'SchemaTagSourceNew',
    tagSourceObject: 'SchemaTagSourceObject',
    table: "SchemaTable",
    tagGroup: "SchemaTagGroup",
    decSource: 'SchemaDecSource'
};
