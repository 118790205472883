<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-14 14:16:44
-->
<template>
  <config-wrap :title="label">
    <div class="select">
      <ul class="select-label">
        <li class="select-label-text">支持点位链接</li>
      </ul>
      <div class="select-body">
        <el-switch v-model="mValue.supportLink"></el-switch>
      </div>
    </div>

    <!-- 标签分组吸顶 -->
    <div class="select">
      <ul class="select-label">
        <li class="select-label-text">标签分组吸顶</li>
      </ul>

      <div class="select-body">
        <div class="mode-select-item flex-center" v-for="(item, index) in doTopModelList" :key="index"
          :class="[mValue.doTopModel == item.value ? 'mode-select-item-active' : '']"
          @click="clickItem(item.value, 'doTopModel')">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>

    <!-- 显示全部分组 -->
    <div class="select">
      <ul class="select-label">
        <li class="select-label-text">显示全部分组</li>
      </ul>

      <div class="select-body">
        <div class="mode-select-item flex-center" v-for="(item, index) in groupModelList" :key="index"
          :class="[mValue.groupModel == item.value ? 'mode-select-item-active' : '']"
          @click="clickItem(item.value, 'groupModel')">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>

    <!-- 显示全部分组 -->
    <div class="select">
      <ul class="select-label">
        <li class="select-label-text">标签分组设置</li>
      </ul>

      <div class="select-body ">
        <el-radio v-model="mValue.tagGroupSet" @input="changeTagGroupSet" :label="item.value"
          v-for="(item, index) in tagGroupSetList" :key="index">{{ item.label }}
        </el-radio>
      </div>
    </div>


    <!-- 标签展示方式 -->
    <div v-if="mValue.tagGroupSet == 'selfSet'">
      <div class="select">
        <ul class="select-label">
          <li class="select-label-text">内容展示方式</li>
        </ul>

        <div class="select-body ">
          <el-radio v-model="mValue.tagShowType" :label="item.value" v-for="(item, index) in conShowType" :key="index">
            {{ item.label }}
          </el-radio>
        </div>
      </div>

      <div style="font-size:13px;color:red;text-align: right;line-height:25px;">
        1.单例：多个状态值的切换(同一个组件);<br />
        2.多例：多个组件的切换(不同组件);
      </div>

    </div>

    <!-- 标签数据源 -->
    <div class="select" v-if="mValue.tagGroupSet != 'selfSet'">

      <ul class="select-label">
        <li class="select-label-text">标签数据源</li>
      </ul>

      <div class="select-body">
        <el-select v-model="mValue.tagSource" size="small" @change="changeCategory">
          <!-- <el-option v-for="item in tagList" :label="item.name" :value="item.url + '?tag=' + item.tag" /> -->
          <el-option v-for="(item, index) in tagList" :key="index" :label="item.name" :value="item.url" />
        </el-select>
      </div>

    </div>


    <div v-if="mValue.tagGroupSet != 'selfSet'" class="select">

      <ul class="select-label">
        <li class="select-label-text">关联组件</li>
      </ul>

      <div class="select-body">

        <el-select v-model="mValue.components" placeholder="请选择" size="small">
          <el-option v-for="(item, index) in curPage.componentEntityList" :key="item.id + '-' + item.component" :label="item.name
    && curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + item.name :
    item.name && curPage.componentEntityList[0].component == 'navTop' ? index + 1 + '-' + item.name :
      !item.name && curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + '-顶部导航' :
        index + 1 + '-顶部导航'" :value="item.id + '-' + item.component"
            :disabled="item.component == 'McTagNav' || item.component == 'navTop'">
            {{
    item.component == 'navTop' || item.component == 'McTagNav' ? '' :
      curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + item.name : index + 1 + '-' +
        item.name
  }}
          </el-option>

        </el-select>

      </div>
    </div>

    <!-- 是否吸顶 -->
    <div class="mt20 addGroup" @click="btnShowGroup" v-show="mValue.tagGroupSet == 'hand'">
      添加分组
    </div>

    <div class="mt20 addGroup mb20" @click="btnAddGroup" v-show="mValue.tagGroupSet == 'selfSet'">
      添加自定义分组
    </div>


    <div v-show="mValue.tagGroupSet == 'selfSet'">
      <!-- 1.单例：多个状态值的切换(同一个组件);<br />
      2.多例：多个组件的切换(不同组件); -->

      <!-- 标签切换  同一个组件 -->
      <div class="self-con mb10">
        <div class="mt10 mr10">参数名字:</div>
        <div style="flex:1">
          <el-input type="text" placeholder="请输入参数名字" v-model="mValue.signSelfSetChinaName" size="small"></el-input>
        </div>
      </div>

      <!-- 单例模式 要选择关联组件和属性 -->
      <div v-if="mValue.tagShowType == 'single'">
        <div class="self-con mb10">
          <div class="mt10 mr10" style="font-size:12px;">关联组件:</div>
          <div style="flex:1">
            <el-select v-model="mValue.components" placeholder="请选择" size="small">

              <el-option v-for="(item, index) in curPage.componentEntityList" :key="item.id + '-' + item.component"
                :label="item.name && curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + item.name :
    item.name && curPage.componentEntityList[0].component == 'navTop' ? index + 1 + '-' + item.name :
      !item.name && curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + '-顶部导航' :
        index + 1 + '-顶部导航'" :value="item.id + '-' + item.component"
                :disabled="item.component == 'McTagNav' || item.component == 'navTop'">
                {{
    item.component == 'navTop' || item.component == 'McTagNav' ? '' :
      curPage.componentEntityList[0].component != 'navTop' ? index + 2 + '-' + item.name : index + 1 + '-' +
        item.name
  }}
              </el-option>

            </el-select>

          </div>

        </div>

        <!-- 标签切换  同一个组件 -->
        <div class="self-con mb10">
          <div class="mt10 mr10">参数属性:</div>
          <div style="flex:1">
            <el-input type="text" placeholder="请输入参数属性" v-model="mValue.sign" size="small"></el-input>
          </div>
        </div>

      </div>


      <div v-for="(item, index) in mValue.selfCategory" :key="index" style="position:relative;" class="self mb20">
        <div class="close" @click="btnDel(index)">
          <span class="iconfont icon-guanbi"></span>
        </div>


        <div class="self-con mb10">
          <div class="mt10 mr10">分组名称:</div>
          <div style="flex:1">
            <el-input type="text" placeholder="请输入标签名" v-model="item.name" size="small"></el-input>
          </div>
        </div>

        <!-- 标签切换  同一个组件 -->
        <div class="self-con mb10" v-if="mValue.tagShowType == 'single'">
          <div class="mt10 mr10" style="padding-left:12px;">参数值:</div>
          <div style="flex:1">
            <el-input type="text" placeholder="请输入参数值" v-model="item.value" size="small"></el-input>
          </div>
        </div>


        <div class="self-con mb10" v-else>
          <div class="mt10 mr10" style="font-size:12px;">关联组件:</div>
          <div style="flex:1">
            <el-select v-model="item.components" placeholder="请选择" size="small" clearable>
              <el-option v-for="(item, index1) in curPage.componentEntityList" :key="item.id + '-' + item.component"
                :label="item.name && curPage.componentEntityList[0].component != 'navTop' ? index1 + 2 + '-' + item.name :
    item.name && curPage.componentEntityList[0].component == 'navTop' ? index1 + 1 + '-' + item.name :
      !item.name && curPage.componentEntityList[0].component != 'navTop' ? index1 + 2 + '-' + '-顶部导航' :
        index1 + 1 + '-顶部导航'" :value="item.id + '-' + item.component" :disabled="disabled(item, index)">
                {{ item.component == 'navTop' || item.component == 'McTagNav' ? '' :
    curPage.componentEntityList[0].component != 'navTop' ? index1 + 2 + '-' + item.name : index1 + 1 + '-' +
      item.name }}
              </el-option>

            </el-select>

          </div>

        </div>


      </div>

      <!-- <el-select v-model="value" placeholder="请选择">
        <el-option-group v-for="group in options" :key="group.label" :label="group.label">
          <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-option-group>
      </el-select> -->

    </div>

    <!-- 手动分组设置 -->
    <div class="groupSet mt20" v-if="mValue.tagGroupSet == 'hand'">
      <div class="zidong">

        <el-tag class="mr10 mb10" v-for="(item, index) in mValue.categoryList" :key="index"
          :closable="item[mValue.showName] != '全部' ? true : false" @close="handleClose(item)">
          {{ item[mValue.showName] }}
        </el-tag>
      </div>
    </div>

    <el-dialog title="信息分组" :visible.sync="show" width="30%">
      <div class="mt30">
        <el-table :data="categoryList" :header-cell-style="{ background: '#f7f8fa', color: '#323233', fontWeight: 500 }"
          style="width: 100%" :header-cell-class-name="cellClass">

          <el-table-column type="selection" align="center">
            <template #default="scope">
              <div class="chooseTeacher">
                <input type="checkBox" @click="select(scope.row)"
                  :checked="checkObjs.includes(scope.row[mValue.clickId])" />
              </div>
            </template>
          </el-table-column>
          <el-table-column :prop="mValue.showName" label="分组名" align='center'></el-table-column>
          <el-table-column prop="createTime" label="创建时间" align='center'></el-table-column>
        </el-table>

        <div slot="footer" class="dialog-footer mt20" style="text-align: center">
          <el-button type="primary" @click="submit">确 定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </config-wrap>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import typeToComponent from "@/config/schema-template";
import { comDSList, getCategoryList, getDatasourceList, getRuleList } from "../../api/easyApp";
import { mapGetters, mapMutations } from "vuex";
import { editProject, pageSave, shopUpdate } from "@/api/project";
import globalMethods from "@/utils/globalMethods";


export default {
  name: "SchemaTagGroup",
  mixins: [schemaMixin],
  props: {
    label: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => {
      },
    },
    tagCategory: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: {},
    }
  },
  inject: ["control"],
  data() {
    return {
      typeToComponent,
      tagList: [],
      curTagInfo: {},
      doTopModelList: [{
        "label": "吸顶",
        "value": true
      }, {
        "label": "不吸顶",
        "value": false
      }],
      groupModelList: [{
        "label": "显示",
        "value": true
      }, {
        "label": "不显示",
        "value": false
      }],
      tagGroupSetList: [{
        "label": "自动",
        //"value": true,
        "value": 'auto',
      }, {
        "label": "手动",
        // "value": false,
        "value": 'hand',
      }, {
        "label": "自定义",
        "value": 'selfSet'
      }

      ],
      conShowType: [{
        "label": "单例",
        "value": 'single',
      }, {
        "label": "多例",
        "value": 'multiple',
      }

      ],
      curTagList: [],
      ruleList: [],
      showName: '',
      clickId: '',
      curTag: '',
      categoryList: [],//分类列表
      show: false,
      checkObjs: [],
      selfCategory: [],
      decRule: [],
      pkId: '',//传参
      pkName: ''//展示
    };
  },
  computed: {
    ...mapGetters(["project", "curPage"])
  },
  async created() {

    /*
     let data = {
          tags: this.tagCategory
        }
        let res = await comDSList(data);
        this.tagList = res.data;*/
    //拿到装修规则（参数名字 和下面列表互动的参数名；以及展示的字段） 通过装修规则 然后换取到映射器里面真正的字段
    let res1 = await getRuleList([this.tagCategory]);
    this.decRule = JSON.parse(res1.data[0].ruleData);

    let clickIndex = this.decRule.findIndex((item) => item.pk == true);
    let showNameIndex = this.decRule.findIndex((item) => !item.pk);
    if (clickIndex != -1) {
      this.pkId = this.decRule[clickIndex].sign;
    }
    if (showNameIndex != -1) {
      this.pkName = this.decRule[showNameIndex].sign;
    }


    console.log("this.pkId:", this.pkId, ";this.pkName:", this.pkName);


    let data = {
      tags: this.tagCategory,
      sceneType: this.project.sceneType + ',GGXX'
    }


    console.log("res1:", this.decRule);

    let res = await getDatasourceList(data);
    this.tagList = res.data;

    /*let result = await getRuleList(['courseCategoryList', 'articleCategoryList', 'categoryList']);
    this.ruleList = result.data;*/

    if (this.mValue.tagSource && this.mValue.tagGroupSet != 'selfSet') {
      //  console.log("this.mValue.tagSourcecacscascsadcccccccccccccccccccccccccccc");
      this.changeCategory(this.mValue.tagSource, 'true');
    }


  },
  methods: {
    ...mapMutations(["setProject", 'setCurPage']),
    //控制可选不可选择
    disabled(item, curindex) {
      let result = false;

      //mValue.selfCategory
      let index = this.mValue.selfCategory.findIndex(items => items.components == item.id + '-' + item.component);

      if (item.component == 'McTagNav' || item.component == 'navTop') {
        result = true
      }
      else if (index != -1 && index != curindex) {
        result = true
      }


      return result
    },
    //
    isCanChooseCom(item) {

      let result = false;
      console.log("isCanChooseComisCanChooseComisCanChooseComisCanChooseCom");

      //:key="item.id+'-'+item.component"
      //item.component=='McTagNav'||item.component=='navTop'
      let index = this.mValue.selfCategory.findIndex(item => item.components == item.id + '-' + item.component);

      if (item.component == 'McTagNav' || item.component == 'navTop') {
        result = true;
      }
      else if (index != -1) {
        result = true;
      }

      console.log("result:", result);

      return false;
    },
    btnSave() {
      this.project.pagesEntityList.forEach(ele => {
        if (ele.pagesId == this.curPage.pagesId) ele.componentEntityList = this.curPage.componentEntityList;
      });

      shopUpdate(this.curPage).then(res => {
        if (res.code == 0) {

          this.setProject(this.project);

        }
      }).catch((err) => {
        this.$message.error(err.msg);
      });

    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
    select(row) {

      const index = this.mValue.categoryList.findIndex(item => item[this.mValue.clickId] == row[this.mValue.clickId]);

      if (index != -1) {
        const index1 = this.checkObjs.findIndex(item => item == row[this.mValue.clickId]);

        this.checkObjs.splice(index1, 1);

        this.mValue.categoryList.splice(index, 1);
      } else {
        this.checkObjs.push(row[this.mValue.clickId]);
        this.mValue.categoryList.push(row);

      }

      // console.log("checkObjs:",this.checkObjs);

    },
    //切换分类
    async changeCategory(val, isStart) {

      console.log("切换分类切换分类切换分类切换分类切换分类val:", val, isStart);


      let data = {
        url: val
      }
      let res = await getCategoryList(data);

      if (isStart != 'true') {
        this.mValue.categoryList = [];
        this.checkObjs = [];
      }


      this.categoryList = res.data;

      // let tag = val.split('tag=');
      let index = this.tagList.findIndex((item) => item.url == val);

      if (index != -1) {
        let info = JSON.parse(this.tagList[index].dataMapperList[0].configContent);
        this.mValue.signChinaName = this.tagList[index].name;
        console.log("info:", info);
        let showNameIndex = info.findIndex((item) => item.target == this.pkName);

        let clickIdIndex = info.findIndex((item) => item.target == this.pkId);

        console.log("showNameIndex:", showNameIndex);
        console.log("clickIdIndex:", clickIdIndex);
        console.log("info:", info);

        /*      console.log("showName:",showName);
              console.log("clickId:",clickId);*/

        /* "showName":"name",
             "clickId":"categoryId",*/


        this.mValue.showName = info[showNameIndex].source;
        this.mValue.clickId = info[clickIdIndex].source;

        /* this.showName = info[showNameIndex].source;
         this.clickId = info[clickIdIndex].source;*/

        /*this.showName = showName;
        this.clickId = clickId;*/

      }

      if (this.mValue.groupModel == true && this.tagList[0][this.mValue.showName] != '全部' && isStart != 'true') {
        let obj = {}
        obj[this.mValue.showName] = '全部';
        obj[this.mValue.clickId] = '123456';
        this.mValue.categoryList.unshift(obj)
      }


      if (isStart == 'true' && this.mValue.tagGroupSet == 'hand' && this.mValue.categoryList.length > 0) {
        for (var i in this.mValue.categoryList) {
          if (this.mValue.categoryList[i][this.mValue.clickId] != '123456') {
            this.checkObjs.push(this.mValue.categoryList[i][this.mValue.clickId]);
          }
        }
      }

      console.log("this.mValuethis.mValuethis.mValuethis.mValuethis.mValuethis.mValuethis.mValue:", this.mValue);

    },
    //确定
    submit() {
      this.show = false;
    },
    //弹出分组框
    btnShowGroup() {
      this.show = true;
    },
    //添加分组
    btnAddGroup() {

      console.log("this.mValue:", this.mValue);

      let obj = {
        name: '',
        components: '',
        sign: '',//参数名 解决多个分组 同一个组件
        value: '',//参数值
      }
      this.mValue.selfCategory.push(obj);

      this.$message({
        message: '添加成功',
        type: 'success'
      });


    },

    btnDel(index) {
      this.mValue.selfCategory.splice(index, 1);
      this.$forceUpdate();
    },

    //删除分组
    handleClose(row) {

      const index = this.mValue.categoryList.findIndex(item => item[this.mValue.clickId] == row[this.mValue.clickId]);
      if (index != -1) {
        const index1 = this.checkObjs.findIndex(item => item == row[this.mValue.clickId]);
        this.checkObjs.splice(index1, 1);
        this.mValue.categoryList.splice(index, 1);
      }

    },
    //
    isCanChoose(value, index) {
      let isShow = false;
      const index1 = this.mValue.selfCategory.findIndex((item) => item.components == value);

      if (index1 != -1 && index1 != index) {
        isShow = true
      }
      // console.log("value:",value,"index1:",index1);
      // console.log("isShow:",isShow);
      return isShow;

    },
    changeTagGroupSet(e) {

      if (!e) {

        if (this.mValue.groupModel == true && ((this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.mValue.showName] != '全部') || this.mValue.categoryList.length == 0)) {
          let obj = {}
          obj[this.mValue.showName] = '全部';
          obj[this.mValue.clickId] = '123456';
          this.mValue.categoryList.unshift(obj)
        } else if (this.mValue.groupModel == false && (this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.mValue.showName] == '全部')) {
          this.mValue.categoryList.splice(0, 1)
        }

        this.$forceUpdate();

        // console.log("this.mValue.categoryList:", this.mValue.categoryList);

      }

    },
    clickItem(value, cate) {

      if (cate == 'doTopModel') {
        this.mValue.doTopModel = value
      } else {
        this.mValue.groupModel = value

        if (this.mValue.tagGroupSet == 'hand') {
          if (this.mValue.groupModel == true && ((this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.mValue.showName] != '全部') || this.mValue.categoryList.length == 0)) {
            let obj = {}
            obj[this.mValue.showName] = '全部';
            obj[this.mValue.clickId] = '123456';
            this.mValue.categoryList.unshift(obj)
          } else if (this.mValue.groupModel == false && (this.mValue.categoryList.length > 0 && this.mValue.categoryList[0][this.mValue.showName] == '全部')) {
            this.mValue.categoryList.splice(0, 1)
          }
        }

      }

    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

.el-select-dropdown__item.is-disabled {
  height: 0px !important;
}


::v-deep .p10 {
  padding: 0px 10px !important;
}

::v-deep .config-item {
  margin-bottom: 1px !important;
  padding: 0px;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;

    .select-label-text {
      width: 90px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }

}


.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0;
  /*no*/
  border-radius: 0;
  font-size: 12px;
  /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;

    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;

  i {
    color: $color-theme;
  }
}

.zidong-con {
  border: 2px dashed #cccccc;
  padding: 10px 0px;
  border-radius: 5px;
  color: #666;
  font-size: 14px;
}

.zidong-head {
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.border {
  border-bottom: 1px dashed #cccccc;
}

.zidong-head-right {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center
}

.addGroup {
  padding: 15px;
  border: 2px dashed #409fee;
  border-radius: 4px;
  text-align: center;
  color: #409fee;
  cursor: pointer;
}

/*// 深度选择器 去掉全选按钮*/
::v-deep .el-table .disabledCheck .cell .el-checkbox__inner {
  display: none;
}

::v-deep .el-table .disabledCheck .cell::before {
  content: '';
  text-align: center;
  line-height: 37px;
}

.self {
  padding: 15px;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.self-con {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #969799;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  color: #fff;
  z-index: 1000;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  background: red;
  border-radius: 50%;
}

.addGroup:hover {
  background: #e0edff;
  color: #155bd4;
  border-color: #155bd4;
}
</style>
