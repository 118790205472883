<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-01 08:57:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 15:18:52
-->
<template>
  <config-item v-show="isShow" :label='label'>
    <div>
      <el-select v-model="mValue.url" size="small" style="width: 100%" @change="bindChange">
        <el-option v-for="item in tagList" :label="item.name" :value="item.url + '?tag=' + tagCategory"/>
      </el-select>
    </div>
  </config-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import {getDatasourceList} from "../../../api/easyApp";

export default {
  name: "SchemaTagSourceObject",
  mixins: [schemaMixin],
  props: {
    labelSwitch: {
      default: true,
    },
    isShow: {
      default: true,
    },
    tagCategory: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: {},
    }
  },

  inject: ['mode'],

  data() {
    return {
      defaultOptions: {
        trueLabel: "显示",
        falseLabel: "隐藏",
      },
      tagList: [],
      valueNew: '',

    };
  },

  async created() {
    let data = {
      tags: this.tagCategory
    }

    let res = await getDatasourceList(data);
    this.tagList = res.data;

    this.bindChange(this.value.url);
  },

  methods: {
    bindChange(e) {
      let row = this.tagList.find(a => (a.url + '?tag=' + this.tagCategory) == e);

      this.value.dsId = row.dsId;
      this.value.dataMapperInfo = {
        ...row.dataMapperList[0],
        configContent: row.dataMapperList[0].configContent ? JSON.parse(row.dataMapperList[0].configContent) : {}
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  /*no*/

  .wrap-label {
    font-size: 14px;
    /*no*/
    line-height: 32px;
    /*no*/
  }
}
</style>