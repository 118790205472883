<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-14 14:16:44
-->
<template>
  <config-wrap :title="label">
    <div class="mb10 f-grey f12" style="line-height: 20px;">{{ mOptions.note }}</div>
    <div class="nav">
      <draggable v-model="mValue">
        <!-- 编译模式,插槽可供自定义拖拽组件入容器 -->
        <slot v-if="edit"></slot>

        <!-- 非编译模式，根据schema子类遍历数组单项组件 -->
        <template v-else>
          <div v-for="item in mValue" :key="item.id" class="nav-item">
            <div style="display: flex;align-items: center;">
              <div style="margin-right: 6px;" v-for="(val, key, index) in schema.child" :key="index"
                v-if="val.type == 'upload'">
                <component :is="typeToComponent[val.type]" v-model="item[key]" v-bind="val"></component>
              </div>

              <div>
                <div v-if="val.type != 'upload'" v-for="(val, key, index) in schema.child" :key="index">
                  <component :is="typeToComponent[val.type]" v-model="item[key]" v-bind="val"></component>
                </div>
              </div>
            </div>

            <div v-if="!mOptions.hideAdd" class="nav-delete" @click="delItem(item.id)">x</div>
          </div>
        </template>
      </draggable>

      <div v-if="!mOptions.hideAdd" class="nav-add" @click="addItem">
        {{ mOptions.buttonName ? mOptions.buttonName : '新增数据' }}
      </div>
    </div>
  </config-wrap>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import typeToComponent from "@/config/schema-template";

export default {
  name: "SchemaArrayImage",

  mixins: [schemaMixin],

  props: {
    label: {
      type: String,
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      default: () => { },
    },
  },

  data() {
    return {
      typeToComponent,
    };
  },

  inject: ['mode'],


  watch: {
    "mode.value.attrs.max": {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);

        const max = newValue;
        const tabListLength = this.mValue.length;

        if (max > tabListLength) {
          const itemsToAdd = max - tabListLength;

          for (let i = 0; i < itemsToAdd; i++) {
            this.mValue.push({
              id: this.$getRandomCode(6),
            })
          }
          console.log(this.mValue);
        }
      },
      deep: true,
      immediate: true,
    }
  },


  methods: {
    addItem() {
      console.log("...mall-cook-platform", this.mode.value.attrs);
      console.log(this.mValue, this.mOptions);
      if (this.mValue.length >= this.mOptions?.limit) {
        this.$notify({
          title: "无法新增",
          message: `最多只能添加${this.mOptions?.limit}条数据`,
          type: "warning",
        });
        return;
      }

      this.mValue.push({
        id: this.$getRandomCode(6),
        jump:{
          "type": "",
          "data": {},
          "id": "",
          "linkPoint": null,
          "initParams": []
        }
      });


    },

    delItem(id) {
      let i = this.mValue.findIndex((item) => item.id == id);
      this.mValue.splice(i, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p10 {
  padding: 0px 10px !important;
}

::v-deep .config-item {
  margin-bottom: 1px !important;
  padding: 0px;
}
</style>
