<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-26 14:16:11
-->
<template>
  <div v-show="isShow">
    <div v-if="label">
      <!-- 确保 FaImageUpload 组件已正确注册 -->
      <config-item :label="label">
        <!-- <FaImageUpload v-model="mValue" v-bind="mOptions" :maxCount="1" /> -->

        <div class="upload" @click="selectIcon" style="position: relative;" @mouseover="showOverlay"
          @mouseleave="hideOverlay" :style="[getStyle()]">

          <i v-if="!mValue" class="el-icon-plus" />
          <i v-else-if="mValue.includes('icon')" :class="mValue" class="f28"></i>
          <el-image v-else :src="mValue" style="max-width: 100%; max-height: 100%;"></el-image>
          <div v-show="isHovered && mValue" class="overlay">
            <i v-if="mValue && mValue.includes('http')" class="el-icon-zoom-in action-icon f15 mr5"
              @click.stop="openImagePreview"></i>
            <i class="el-icon-delete action-icon f15" @click.stop="deleteIcon"></i>
          </div>

        </div>
      </config-item>
    </div>
    <div v-else>
      <!-- <FaImageUpload v-model="mValue" v-bind="mOptions" :maxCount="1" /> -->

      <div class="upload" @click="selectIcon" style="position: relative;" @mouseover="showOverlay"
        @mouseleave="hideOverlay" :style="[getStyle()]">

        <i v-if="!mValue" class="el-icon-plus" />
        <i v-else-if="mValue.includes('icon')" :class="mValue" class="f28"></i>
        <el-image v-else :src="mValue" style="max-width: 100%; max-height: 100%;"></el-image>
        <div v-show="isHovered && mValue" class="overlay">
          <i v-if="mValue && mValue.includes('http')" class="el-icon-zoom-in action-icon f15 mr5"
            @click.stop="openImagePreview"></i>
          <i class="el-icon-delete action-icon f15" @click.stop="deleteIcon"></i>
        </div>

      </div>
    </div>
    <div class="note">{{ mOptions.note }}</div>

    <material ref="mater" @iconConfirm="selectItem" :uploadType="mOptions.uploadType"></material>

    <ElImageViewer v-if="offerListShow" :on-close="closeImgView" :url-list="offerList" style="z-index: 9999">
    </ElImageViewer>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import material from "components/material";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  name: "SchemaUpload",
  mixins: [schemaMixin],
  components: {
    material,
    ElImageViewer,
  },
  props: {
    isShow: {
      default: true,
    }
  },
  data() {
    return {
      isHovered: false,
      defaultOptions: {
        uploadType: 'image'
      },

      offerListShow: false,
      offerList: [],
    }
  },
  methods: {
    // 关闭预览图片
    closeImgView() {
      this.offerListShow = false;
    },

    // 上传
    selectIcon() {
      if (this.isHovered && this.mValue) return;

      this.$refs["mater"].open();
    },

    // 鼠标悬浮
    showOverlay() {
      this.isHovered = true;
    },

    // 鼠标移开
    hideOverlay() {
      this.isHovered = false;
    },

    // 删除图标或图片
    deleteIcon() {
      console.log('删除图标或图片');
      this.mValue = null;
    },

    // 选择图标或图片
    selectItem(e) {
      console.log('selectItem---', e);
      this.mValue = e.route;
      this.mOptions.uploadKey = e.key;
    },

    // 预览图片
    openImagePreview() {
      this.offerList = [this.mValue];
      this.offerListShow = true;
    },

    // 边框样式
    getStyle() {
      let result = {
        color: '#333333',
      }
      if (!this.isHovered && !this.mValue) {
        result.border = '1px dashed #d9d9d9';
        return result;
      } else if (this.isHovered && !this.mValue) {
        result.color = '#409eff';
        result.border = '1px dashed #409eff';
        return result;
      } else if (!this.isHovered && this.mValue) {
        result.border = '1px solid #d9d9d9';
        return result;
      } else if (this.isHovered) {
        result.border = 'none';
        return result;
      }

    },
  }
};
</script>

<style lang="scss" scoped>
.upload {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;

  i,
  el-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.upload:hover {
  background-color: #fff;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s;
}

.action-icon {
  color: white;
  font-size: 18px;
}

.note {
  font-size: 12px;
  color: #969799;
  transform: translateX(26%);
}
</style>