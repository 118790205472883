<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-07 16:29:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-06-08 10:36:50
-->
<template>
  <div class="select" style="padding: 0 8px;width: 225px;">
    <div class="select-label" v-if="!cate">{{ mValue }}</div>
    <div class="select-label" v-if="cate"></div>

    <div class="flex" style="justify-content: end;align-items: center;">
      <span class="f12 f-theme pointer" @click="bindReset">重置</span>
      <el-color-picker v-model="mValue" :predefine="predefineColors" v-bind="mOptions"></el-color-picker>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigColorPicker",
  props: {
    value: {},
    cate: "",
    mOptions: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      mValue: "",
      predefineColors: [
        "#ffffff",
        "#f5f5f5",
        "#F2F2F2",
        "#FF4444",
        "#FFCD00",
        "#3FBC87",
        "#5197FF",
        "#BADCFF",
        "#000000",
      ],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mValue = newValue;
      },
    },
    mValue: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      },
    },
  },

  methods: {
    bindReset() {
      console.log('bindReset------', this.mOptions)
      this.mValue = this.mOptions ? this.mOptions.default : '#FFFFFF'
    }
  }
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;

  .select-label {
    display: flex;
    align-items: center;
    color: #969799;
    font-size: 14px;

    .select-label-text {
      width: 77px;
      color: #969799;
      font-size: 13px;
    }
  }

  .select-body {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
